<template>
  <div class="content-container">
    <ty-item-title
      title="问卷基础信息"
      :go-back-button="true"
      @goBack="goBack"
    ></ty-item-title>

    <div class="detail-content">
      <div class="content-title">{{ form.questionnaireName }}</div>

      <div class="form-container">
        <el-form label-width="85px" label-position="right" style="width: 880px">
          <el-form-item label="新增方式：">
            <span>{{ form.addType == 0 ? "普通新增" : "模板新增" }}</span>
          </el-form-item>
          <el-form-item label="开头语：">
            <span>{{ form.openingWords }}</span>
          </el-form-item>
          <el-form-item label="绑定单品：">
            <el-tag
              v-for="(tag, index) in form.products"
              :key="index"
              class="tag-item"
              size="small"
              type="info"
              >{{ tag.productCode }}{{ tag.relationProductName }}</el-tag
            >
          </el-form-item>
          <el-form-item label="问题：" style="position: relative; top: 11px">
            <div class="question-list">
              <div
                v-for="(quesiton, index) in form.problems"
                :key="index"
                class="question-item"
              >
                <span class="question-left">
                  <span class="index">{{ index + 1 }}.&nbsp;&nbsp;</span>
                  {{ quesiton.content }}
                </span>

                <span class="question-right">
                  <span class="tag orange-tag">{{
                    quesiton.problemType == "0"
                      ? "自定义"
                      : quesiton.problemType == "1"
                      ? "数据字典"
                      : quesiton.problemType == "2"
                      ? "标签库"
                      : quesiton.problemType == "3"
                      ? "拍摄产品"
                      : "拍摄人"
                  }}</span>

                  <span class="tag blue-tag">{{
                    quesiton.type == "0"
                      ? "单选题"
                      : quesiton.type == "1"
                      ? "多选题"
                      : quesiton.problemType == "2"
                      ? "日起编辑题"
                      : quesiton.type == "3"
                      ? "填空题"
                      : "图片编辑题"
                  }}</span>
                </span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import tyItemTitle from "@/components/ty-item-title/index.vue";
export default {
  components: {
    tyItemTitle,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/questionnaireManage/index",
        query: { listType: "questionnaire" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  // margin-left: 24px;

  .detail-content {
    padding-top: 36px;
    padding-left: 24px;
    width: 100%;

    .content-title {
      color: #111;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 24px;
      text-align: center;
    }

    .form-container {
      width: 100%;
      display: flex;
      justify-content: center;

      ::v-deep .el-form-item__label {
        color: #9a9d9e;
        line-height: 20px;
      }

      ::v-deep .el-form-item__content {
        line-height: 20px;
      }

      ::v-deep .el-form-item {
        margin-bottom: 10px;
      }

      .tag-item {
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .question-list {
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        bottom: 11px;

        .question-item {
          border: 1px solid #eaeaea;
          border-radius: 4px;
          padding: 10px 16px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;

          .question-left {
            font-size: 14px;
            margin-right: 50px;
            white-space: nowrap;

            .index {
              color: #a4a8ac;
            }
          }

          .question-right {
            font-size: 12px;
            white-space: nowrap;

            .tag {
              padding: 4px 6px;
              margin-right: 6px;
              white-space: nowrap;
            }

            .tag:last-child {
              margin-right: 0;
            }

            .orange-tag {
              background: rgba(255, 132, 38, 0.08);
              color: rgba(241, 125, 37, 0.8);
            }

            .blue-tag {
              background: rgba(38, 144, 255, 0.08);
              color: rgba(38, 144, 255, 0.8);
            }
          }
        }
      }
    }
  }
}
</style>
