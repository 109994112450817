<template>
  <div class="questionnaireDetail">
    <el-tabs v-model="detailType">
      <el-tab-pane label="基础信息" name="1"></el-tab-pane>
      <el-tab-pane name="2">
        <span slot="label"> 问卷提交详情</span>
      </el-tab-pane>
    </el-tabs>

    <base-info v-if="detailType == '1'" :form="form"></base-info>
    <submit-detail
      v-else
      ref="submitDetail"
      :result-list="resultList"
      :questionnaire-name="form.questionnaireName"
      :questionnaire-id="idCopy"
      :question-list="questionList"
      :total="total"
      @search="submitSearch"
    ></submit-detail>
  </div>
</template>

<script>
import baseInfo from "./components/baseInfo.vue";
import submitDetail from "./components/submitDetail.vue";
import {
  getQuestionnaireById,
  getQuestionnaireSubmitList,
  getTitleQuestion,
} from "@/api/questionnaire";

export default {
  components: {
    baseInfo,
    submitDetail,
  },
  data() {
    return {
      detailType: "1",
      form: {
        questionnaireName: "",
        openingWords: "",
        addType: 0,
        problems: [],
        products: [],
      },
      resultNum: 0,
      resultList: [],
      questionList: [],
      total: 0,
    };
  },
  computed: {
    idCopy() {
      return this.$route.query.id;
    },
  },
  async mounted() {
    if (this.$route.query.id) {
      await this.getQuestionList();
      const res = await Promise.all([
        getQuestionnaireById({ data: this.idCopy }),
        this.submitSearch(),
      ]);

      const { questionnaireName, openingWords, addType, problems, products } =
        res[0].data;
      this.form = {
        questionnaireName,
        openingWords,
        addType,
        problems,
        products: (products || []).map((el) => ({
          relationProductName: el.tyProductName,
          productCode: el.tyProductCode,
          relationProductId: el.tyProductId,
        })),
      };

      this.resultNum = res[1].data.total;
    }
  },
  methods: {
    async submitSearch(searchForm) {
      let reqObj = {};
      if (!searchForm) {
        reqObj = {
          data: {
            questionnaireId: this.idCopy,
            isSubmit: 1,
          },
          pageSize: 10,
          pageNumber: 1,
        };
      } else {
        reqObj = searchForm;
      }

      const res = await getQuestionnaireSubmitList(reqObj);
      if (!searchForm) return new Promise((resolve) => resolve(res));
      if (res.success) {
        this.total = +res.data.total
        this.resultList = res.data.content.map((el) => ({
          ...el,
          results:
            el.results ||
            Array(this.questionList.length).fill({ problemResult: "/" }),
          sendTime: el.sendTime?.replace("T", " ") || "/",
          submitTime: el.submitTime?.replace("T", " ") || "/",
          updateTime: el.updateTime?.replace("T", " ") || "/",
        }));
      }
    },
    async getQuestionList() {
      const reqObj = {
        data: {
          questionnaireId: this.idCopy,
        },
      };

      const res = await getTitleQuestion(reqObj);
      if (res.success) {
        console.log(res.data);
        this.questionList = res.data.filter((el, index) => index >= 4);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaireDetail {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  overflow-x: hidden;
}
</style>
