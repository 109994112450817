<template>
  <div class="content-container">
    <ty-item-title title="问卷提交详情" :go-back-button="true" @goBack="goBack"></ty-item-title>

    <div class="detail-content">
      <div class="content-title">{{ questionnaireName }}</div>

      <div style="margin-bottom: 20px">
        <el-input
          v-model="searchForm.userPhone"
          placeholder="输入手机号"
          prefix-icon="el-icon-search"
          style="width: 240px; margin-right: 20px"
          size="mini"
        ></el-input>

        <el-input
          v-model="searchForm.serviceNo"
          placeholder="输入服务单号"
          prefix-icon="el-icon-search"
          style="width: 240px; margin-right: 20px"
          size="mini"
        ></el-input>

        <el-select v-model="searchForm.isSubmit" placeholder="是否提交问卷" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>

        <el-button style="margin-left: 10px" size="mini" type="primary" @click="search"
          >查询</el-button
        >
        <el-button size="mini" @click="reset">重置</el-button>
      </div>

      <el-table style="width: 96%" max-height="600px" :data="resultListCopy" border>
        <el-table-column prop="userPhone" label="手机号" width="110"></el-table-column>
        <el-table-column prop="serviceNo" label="服务订单" width="250">
          <template slot-scope="scope">
            <span v-if="!scope.row.serviceNo" class="text-button" @click="addOrEdit(0, scope.row)"
              >添加服务订单</span
            >
            <span v-else>
              <span>{{ scope.row.serviceNo }}</span>
              <span
                v-if="scope.row.isUpdate == '1'"
                style="margin-left: 10px"
                class="text-button"
                @click="addOrEdit(1, scope.row)"
                >编辑</span
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="sendTime" label="发送时间" width="180"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="180"></el-table-column>

        <template v-if="questionListCopy.length">
          <el-table-column
            v-for="(result, index) in questionListCopy"
            :key="index"
            :label="
              result.relatedTagValue
                ? result.relatedTagValue
                : result.relatedUserFieldNote
                ? result.relatedUserFieldNote
                : result.title
            "
          >
            <!-- 表头 -->
            <template slot="header" slot-scope="scope">
              <span>
                <span>{{ scope.column.label }}</span>
                <el-tooltip
                  v-if="result.relatedTagValue || result.relatedUserFieldNote"
                  placement="top"
                  :content="result.title"
                >
                  <i style="color: rgba(135, 135, 135, 1)" class="el-icon-warning-outline"></i>
                </el-tooltip>
              </span>
            </template>
            <!-- 表格数据行 -->
            <template v-if="scope.row.results[index]" slot-scope="scope">
              <span
                v-if="
                  scope.row.results[index].problemType !== 4 &&
                  scope.row.results[index].problemType !== 3 &&
                  scope.row.results[index].type !== 4
                "
              >
                {{ scope.row.results[index].problemResult }}
              </span>
              <template v-if="scope.row.results[index].problemType === 4">
                <el-button v-if="scope.row.serviceNo" type="text" @click="openRelatives(scope.row)"
                  >查看拍摄人</el-button
                >
                <span v-else>/</span>
              </template>
              <el-button
                v-if="scope.row.results[index].type === 4"
                type="text"
                @click="openViewer(scope.row.results[index])"
                >查看图片</el-button
              >
              <el-tooltip v-if="scope.row.results[index].problemType === 3" placement="bottom">
                <div slot="content">
                  <div
                    v-for="item in scope.row.results[index].samples"
                    :key="item.relatedSampleCode"
                  >
                    {{ item.relatedSampleCode }}{{ item.relatedSampleName }}*{{
                      item.relatedSampleNum
                    }}；
                  </div>
                </div>
                <span class="product-text">{{
                  scope.row.results[index].samples | productNameFilters
                }}</span>
              </el-tooltip>
              <!-- {{ scope.row.results[index].problemResult }} -->
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div>
      <el-pagination
        style="width: 96%;"
        :current-page="page.pageNumber"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="  prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :title="`${addType == 0 ? '添加' : '编辑'}服务订单`"
      width="30%"
    >
      <div style="display: flex; align-items: center">
        <span style="min-width: 90px">服务订单：</span>
        <el-input v-model="serviceNo" size="mini"></el-input>
      </div>
      <span slot="footer">
        <el-button
          size="mini"
          @click="
            dialogVisible = false
            serviceNo = ''
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="submitServiceNo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看图片" :visible.sync="dialogFormVisible">
      <div>
        <el-image
          v-for="(item, index) in viewerList"
          :key="index"
          class="image"
          :src="item"
          fit="cover"
          :preview-src-list="viewerList"
        >
        </el-image>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <SelectRelativesDrawer
      :show-drawer.sync="showDrawer"
      :service-no="serviceNo"
      :user-id="userId"
    />
  </div>
</template>

<script>
import tyItemTitle from '@/components/ty-item-title/index.vue'
import { addServiceNo } from '@/api/questionnaire'

export default {
  components: {
    tyItemTitle,
  },
  filters: {
    productNameFilters: function (value) {
      if (!value) return ''

      const newArr = value
        .map((el) => el.relatedSampleCode + el.relatedSampleName + '*' + el.relatedSampleNum)
        .join(';')

      return newArr
    },
  },
  props: {
    resultList: {
      type: Array,
      default: () => [],
    },
    questionnaireName: {
      type: String,
      default: '',
    },
    questionnaireId: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '0',
    },
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [
        { value: 0, label: '未提交' },
        { value: 1, label: '已提交' },
      ],
      searchForm: {
        userPhone: '',
        serviceNo: '',
        isSubmit: null,
        questionnaireId: this.questionnaireId,
      },
      page: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
      },
      dialogVisible: false,
      addType: null,
      serviceNo: '',
      currentId: '',
      dialogFormVisible: false,
      viewerList: [],
      showDrawer: false,
      userId: '',
    }
  },
  computed: {
    resultListCopy() {
      return this.resultList
    },
    questionListCopy() {
      console.log('this.questionList', this.questionList)
      return this.questionList || []
    },
  },
  watch:{
    total(val){
      this.page.total = val;
    }
  },
  async mounted() {
    await this.search()
  },
  methods: {
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.search(false);
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.search(false);
    },
    openRelatives(row) {
      console.log(row)
      this.showDrawer = true
      this.serviceNo = row.serviceNo
      this.userId = row.userId
    },
    openViewer(row) {
      this.dialogFormVisible = true
      if (row.problemResult) {
        this.viewerList = row.problemResult.split(',')
      }
    },
    goBack() {
      this.$router.push({
        path: '/questionnaireManage/index',
        query: { listType: 'questionnaire' },
      })
    },
    async search(isReset = true) {
      if(isReset){
        this.page.pageSize = 10
        this.page.pageNumber = 1
      }
      const searchForm = {
        data: this.searchForm,
        ...this.page
      }

      await this.$emit('search', searchForm)
    },
    reset() {
      this.searchForm.userPhone = ''
      this.searchForm.serviceNo = ''
      this.searchForm.isSubmit = null
      this.page.pageSize = 10
      this.page.pageNumber = 1
      this.search()
    },
    addOrEdit(type, row) {
      this.currentId = row.id
      this.addType = type
      this.dialogVisible = true
    },
    async submitServiceNo() {
      const reqObj = {
        data: {
          id: this.currentId,
          serviceNo: this.serviceNo,
        },
      }

      const res = await addServiceNo(reqObj)
      if (res.success) {
        this.$message.success(this.addType === 0 ? '添加成功' : '修改成功')
        this.serviceNo = ''
        this.dialogVisible = false
        this.search()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  // margin-left: 24px;

  .detail-content {
    padding-top: 36px;
    padding-left: 24px;

    .content-title {
      color: #111;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .text-button {
      color: #2861e2;
      cursor: pointer;
    }

    .product-text {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
.image {
  margin-right: 12px;
  margin-bottom: 12px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}
</style>
